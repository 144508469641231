<template>
  <v-card
	class="overflow-hidden"
	color="purple lighten-1"
	dark
  >
	<v-toolbar
		flat
		color="purple"
	>
		<v-icon>mdi-account</v-icon>
		<v-toolbar-title class="font-weight-light">
		User Profile
		</v-toolbar-title>
		<v-spacer></v-spacer>
	</v-toolbar>
	<v-card-text>
		<v-text-field
		color="white"
		label="Name"
		v-model="n.name"
		></v-text-field>

		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Soleil"
			v-model="n.soleil"
		></v-autocomplete>
		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Ascendant"
			v-model="n.ascendant"
		></v-autocomplete>
		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Lune"
			v-model="n.lune"
		></v-autocomplete>
		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Mars"
			v-model="n.mars"
		></v-autocomplete>
		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Venus"
			v-model="n.venus"
		></v-autocomplete>
		<v-autocomplete
			:items="signes"
			:filter="customFilter"
			color="white"
			item-text="name"
			item-value="id"
			label="Mercure"
			v-model="n.mercure"
		></v-autocomplete>
	</v-card-text>
	<v-divider></v-divider>
	<v-card-actions>
		<v-spacer></v-spacer>
		<v-btn
		color="success"
		@click="save"
		>
		Save
		</v-btn>
	</v-card-actions>
  </v-card>
</template>

<script>
	export default {
		name: 'AddPerson',
		data() {
			return {
				model: null,
				signes: [
					{ name: "Bélier", id: 1 },
					{ name: 'Taureau', id: 2 },
					{ name: 'Gémeaux', id: 3 },
					{ name: 'Cancer', id: 4 },
					{ name: 'Lion', id: 5 },
					{ name: 'Vierge', id: 6 },
					{ name: 'Balance', id: 7 },
					{ name: 'Scorpion', id: 8 },
					{ name: 'Sagittaire', id: 9 },
					{ name: 'Capricorne', id: 10 },
					{ name: 'Verseau', id: 11 },
					{ name: 'Poisson', id: 12 },
				],
				n: {
					name: null,
					soleil: null,
					ascendant: null,
					lune: null,
					mars: null,
					venus: null,
					mercure: null,
				}
			}
		},

		methods: {
			customFilter (item, queryText) {
				const textOne = item.name.toLowerCase()
				const searchText = queryText.toLowerCase()

				return textOne.indexOf(searchText) > -1
			},
			save () {
				this.$emit('addUser', this.n)
			},
		},
	}
</script>