<template>
	<v-card
		class="overflow-hidden"
		color="red lighten-1"
		dark
	>
		<v-toolbar
			flat
			color="red"
		>
			<v-icon>mdi-star</v-icon>
			<v-toolbar-title class="font-weight-light">
				Astro checker
			</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>
		<v-card-text>

			<center>
				<v-row justify="space-between" style="max-width: 1000px">
					<v-autocomplete
					:items="users"
					:filter="customFilter"
					color="white"
					item-text="name"
					item-value="id"
					label="Personne 1"
					chips
					style="margin-right: 20px"
					v-model="u"
				></v-autocomplete>

				<v-autocomplete
					:items="users"
					:filter="customFilter"
					color="white"
					item-text="name"
					item-value="id"
					label="Personn 2"
					chips
					style="margin-left: 20px"
					v-model="v"
				></v-autocomplete>
				</v-row>

				<v-btn
					style="margin-top: 10px"
					color="yellow"
					@click="computeValence"
				>
					GOOO
				</v-btn>
			</center>

			<v-divider style="margin-top: 30px;"></v-divider>

			<div v-if="score" style="border: solid 1px white; border-radius: 10px; margin: 10px; padding: 10px">
				AZEAZE {{ users[u].name }} : {{ users[v].name }} = {{ score }}
			</div>

		</v-card-text>
		<v-snackbar
			v-model="hasSaved"
			:timeout="2000"
			absolute
			bottom
			left
		>
			Your profile has been updated
		</v-snackbar>
	</v-card>
</template>

<script>
	export default {
		name: 'ComatibilityChecker',
		props: [ "users" ],
		data() {
			return {
				u: null,
				v: null,
				score: 0,
				hasSaved: false,
				model: null,

				score1: [
					[ 3, 0, 2, 0, 4, 0, 2, 0, 4, 0, 2, 0 ],
					[ 0, 3, 0, 2, 0, 4, 0, 2, 0, 4, 0, 2 ],
					[ 2, 0, 3, 0, 2, 0, 4, 0, 2, 0, 4, 0 ],
					[ 0, 2, 0, 3, 0, 2, 0, 4, 0, 2, 0, 4 ],
					[ 4, 0, 2, 0, 3, 0, 2, 0, 4, 0, 2, 0 ],
					[ 0, 4, 0, 2, 0, 3, 0, 2, 0, 4, 0, 2 ],
					[ 2, 0, 4, 0, 2, 0, 3, 0, 2, 0, 4, 0 ],
					[ 0, 2, 0, 4, 0, 2, 0, 3, 0, 2, 0, 4 ],
					[ 4, 0, 2, 0, 4, 0, 2, 0, 3, 0, 2, 0 ],
					[ 0, 4, 0, 2, 0, 4, 0, 2, 0, 3, 0, 2 ],
					[ 2, 0, 4, 0, 2, 0, 4, 0, 2, 0, 3, 0 ],
					[ 0, 2, 0, 4, 0, 2, 0, 4, 0, 2, 0, 3 ]
				],

				score2: [
					[ 5, 0, 3, 3, 4, 1, 4, 1, 4, 3, 3, 0 ],
					[ 0, 5, 0, 3, 3, 4, 1, 4, 1, 4, 3, 3 ],
					[ 3, 0, 5, 0, 3, 3, 4, 1, 4, 1, 4, 3 ],
					[ 3, 3, 0, 5, 0, 3, 3, 4, 1, 4, 1, 4 ],
					[ 4, 3, 3, 0, 5, 0, 3, 3, 4, 1, 4, 1 ],
					[ 1, 4, 3, 3, 0, 5, 0, 3, 3, 4, 1, 4 ],
					[ 4, 1, 4, 3, 3, 0, 5, 0, 3, 3, 4, 1 ],
					[ 1, 4, 1, 4, 3, 3, 0, 5, 0, 3, 3, 4 ],
					[ 4, 1, 4, 1, 4, 3, 3, 0, 5, 0, 3, 3 ],
					[ 3, 4, 1, 4, 1, 4, 3, 3, 0, 5, 0, 3 ],
					[ 3, 3, 4, 1, 4, 1, 4, 3, 3, 0, 5, 0 ],
					[ 0, 3, 3, 4, 1, 4, 1, 4, 3, 3, 0, 5 ]
				]
			}
		},

		methods: {
			customFilter (item, queryText) {
				const textOne = item.name.toLowerCase()
				const searchText = queryText.toLowerCase()

				return textOne.indexOf(searchText) > -1
			},
			save () {
				this.hasSaved = true
			},

			computeValence(){

				let comparisons1 = [
					{
						a: 'soleil',
						b: 'soleil'
					},
					{
						a: 'lune',
						b: 'lune'
					},
					{
						a: 'venus',
						b: 'venus'
					},
					{
						a: 'mars',
						b: 'mars'
					},
					{
						a: 'lune',
						b: 'venus'
					},
					{
						a: 'venus',
						b: 'lune'
					},
					{
						a: 'mars',
						b: 'ascendant'
					},
					{
						a: 'ascendant',
						b: 'mars'
					},
					{
						a: 'ascendant',
						b: 'ascendant'
					},
					{
						a: 'mars',
						b: 'soleil'
					},
					{
						a: 'soleil',
						b: 'mars'
					},
				]

			let comparisons2 = [
					{
						a: 'soleil',
						b: 'lune'
					},
					{
						a: 'lune',
						b: 'soleil'
					},
					{
						a: 'venus',
						b: 'ascendant'
					},
					{
						a: 'venus',
						b: 'mars'
					},
					{
						a: 'ascendant',
						b: 'venus'
					},
					{
						a: 'venus',
						b: 'soleil'
					},
					{
						a: 'lune',
						b: 'ascendant'
					},
					{
						a: 'lune',
						b: 'mars'
					},
					{
						a: 'ascendant',
						b: 'lune'
					},
					{
						a: 'mars',
						b: 'venus'
					},
					{
						a: 'soleil',
						b: 'venus'
					},
					{
						a: 'mars',
						b: 'lune'
					},
				]

				let u = this.$props.users[this.u]
				let v = this.$props.users[this.v]

				console.log(this.$props.users)
				console.log(u, v)


				for (let o of comparisons1) {
					this.score += this.score1[ u[o.a]-1 ][ v[o.b]-1 ]
				}

				for (let o of comparisons2) {
					this.score += this.score2[ u[o.a]-1 ][ v[o.b]-1 ]
				}

				this.score *= 100/104

			}

		},
	}
</script>