<template>
	<v-app id="inspire">
		<v-navigation-drawer
			v-model="drawer"
			app
			class="pt-4"
			color="grey lighten-3"
			mini-variant
		>
			<v-avatar
				:color="`cyan ${tab == 0 ? 'darken' : 'lighten'}-1`"
				:size="tab == 0 ? 36 : 20"
				class="d-block text-center mx-auto mb-9"
				@click="tab=0"
			></v-avatar>
			<v-avatar
				:color="`purple ${tab == 1 ? 'darken' : 'lighten'}-1`"
				:size="tab == 1 ? 36 : 20"
				class="d-block text-center mx-auto mb-9"
				@click="tab=1"
			></v-avatar>
		</v-navigation-drawer>

		<v-main style="margin: 50px">
			<CompatibilityChecker :users="users" v-if="tab == 0"/>
			<AddPerson @addUser="addUser" v-if="tab == 1"/>
		</v-main>
	</v-app>
</template>

<script>
	import AddPerson from './components/AddPerson'
	import CompatibilityChecker from './components/CompatibilityChecker'

	export default {
		name: 'App',
		components: {
			AddPerson, CompatibilityChecker
		},

		data(){
			return {
				tab: 0,
				users: []
			}
		},

		methods: {
			addUser(user){
				this.users.push({
					id: this.users.length,
					...user
				})
				window.localStorage.setItem('users', JSON.stringify(this.users))
			}
		},

		mounted() {
			this.users = JSON.parse(window.localStorage.getItem('users'))
			// console.log("loaded", this.susers)
		}
	}
</script>